import React, { FC, useEffect, useState } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"

import MobileHeader from "../components/mobile/MobileHeader"
import MobileFooter from "../components/mobile/MobileFooter"
import styled from "styled-components"
import MobileDienstleistungen from "../components/mobile/MobileDienstleistungen"
import MobileAbout from "../components/mobile/MobileAbout"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Contact from "../components/Contact"
import DesktopHeader from "../components/desktop/DesktopHeader"
import Sidebar from "../components/desktop/Sidebar"
import DesktopDienstleistungen from "../components/desktop/DesktopDienstleistungen"
import DesktopAbout from "../components/desktop/DesktopAbout"
import { FaArrowAltCircleUp } from "react-icons/fa"
import { navigate } from "@reach/router"
import { useViewportScroll, AnimatePresence, motion } from "framer-motion"

const MobileContainer = styled.div`
  /* margin-bottom: var(--s16); */
  background: var(--off-white);
  @media screen and (min-width: 900px) {
    display: none;
  }
`

const DesktopContainer = styled.div`
  margin-left: var(--sidebar-width);
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const ArrowUp = styled(motion.button)`
  position: fixed;
  bottom: var(--s2);
  right: var(--s2);
  z-index: 9999;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--grey);
  &:hover {
    color: var(--primary-dark);
  }
  @media screen and (max-width: 900px) {
    bottom: var(--s24);
    right: var(--s1);
    color: var(--light-grey);
    z-index: 9;
  }
`



const IndexPage: FC = () => {
  const { language } = useI18next()
  const { scrollYProgress } = useViewportScroll()
  const [isUpArrowVisible, setIsUpArrowVisible] = useState(false)

  useEffect(() => {
    function updateOpacity() {
      if (scrollYProgress.get() > 0.1) {
        setIsUpArrowVisible(true)
      } else {
        setIsUpArrowVisible(false)
      }
    }
    const unsubscribe = scrollYProgress.onChange(updateOpacity)
    return unsubscribe
  }, [])

  return (
    <Layout>
      <AnimatePresence>
        {isUpArrowVisible && (
          <ArrowUp
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            onClick={() => navigate("/")}
          >
            <FaArrowAltCircleUp size="25" />
          </ArrowUp>
        )}
      </AnimatePresence>
      <SEO title="Sandra Cox | Konferenzdolmetscherin" lang={language}/>
      <Sidebar />
      <DesktopContainer>
        <DesktopHeader />
        <DesktopDienstleistungen />
        <DesktopAbout />
      </DesktopContainer>
      <MobileContainer>
        <MobileHeader />
        <MobileDienstleistungen />
        <MobileAbout />
      </MobileContainer>
      <Contact />
      <MobileFooter />
    </Layout>
  )
}

export default IndexPage
