import { useState, useEffect } from "react"

export function useCssProperties(properties: string[]) {
  const [variables, setVariables] = useState<Record<string, string>>({})

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const bodyStyles = getComputedStyle(document.body)
      const newVariables = {}
      for (let property of properties) {
        // @ts-ignore
        newVariables[property] = bodyStyles.getPropertyValue(property)
      }

      setVariables(newVariables)
    }
  }, [])
  return variables
}
