import React, { FC, ReactElement, useState } from "react"
import { FaArrowDown } from "react-icons/fa"
import styled from "styled-components"
import { motion, AnimatePresence, Variants } from "framer-motion"
import scrollTo from "gatsby-plugin-smoothscroll"

export type AccordionItem = {
  title: string
  content: ReactElement
}

type Props = {
  items: AccordionItem[]
  color: string
}

const Container = styled("div")<{ color: string }>`
  background: var(--white);
  padding: var(--s8);
  padding-bottom: var(--s16);
  margin-top: var(--s8);
  border-bottom: 3px solid ${props => props.color};
`

const TitleRow = styled("button")<{ color: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  align-items: center;
  margin-top: var(--s10);
  border-bottom: 1px solid ${props => props.color};
  cursor: pointer;
  h4 {
    font-weight: bold;
    font-size: 2rem;
    color: ${props => props.color};
    text-align: left;
  }
`

const accordionVariants: Variants = {
  closed: {
    height: 0,
    opacity: 0,
    display: "none",
  },
  open: {
    height: "auto",
    opacity: 1,
    display: "block",
    transition: {
      bounceDamping: 20,
    },
  },
}

const Accordion: FC<Props> = ({ items, color }, props) => {
  const [activeItem, setActiveItem] = useState(-1)

  const handleTitleClick = (index: number, title: string) => {
    if (index === activeItem) {
      setActiveItem(-1)
    } else {
      setActiveItem(index)
      setTimeout(() => scrollTo(`#${title.replace(" ", "").replace(" ", "")}`), 50)
    }
  }
  return (
    <Container color={color} {...props}>
      {items.map((item, i) => (
        <div key={i}>
          <TitleRow
            id={item.title.replace(" ", "").replace(" ", "")}
            onClick={() => handleTitleClick(i, item.title)}
            color={color}
          >
            <h4>{item.title}</h4>
            <FaArrowDown color={color} />
          </TitleRow>
          <AnimatePresence>
            {activeItem === i && (
              <motion.div variants={accordionVariants} initial="closed" animate="open" exit="closed">
                {item.content}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </Container>
  )
}

export default Accordion
