import React, { FC } from "react"
import { motion } from "framer-motion"
import { useCssProperties } from "../hooks/useCssProperties"

const logo: FC = props => {
  const colors = useCssProperties(["--primary"])

  return (
    <svg {...props} width="56" height="54" viewBox="0 0 56 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <motion.path
          d="M6.11114 34.9727L7.61378 33.8398L6.17432 32.6276C3.07419 30.0169 1.50013 26.8547 1.50013 23.6252C1.50013 19.8979 3.63221 16.3711 7.36895 13.7161C11.1016 11.0641 16.3316 9.37524 22.1667 9.37524C28.0018 9.37524 33.2318 11.0641 36.9645 13.7161C40.7012 16.3711 42.8333 19.8979 42.8333 23.6252C42.8333 27.3524 40.7012 30.8792 36.9645 33.5342C33.2318 36.1863 28.0018 37.8752 22.1668 37.8752H2.26134L6.11114 34.9727Z"
          stroke={colors["--primary"]}
          strokeWidth="3"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1.5 }}
        />
        <path
          d="M55.552 44.1136L50.7897 40.5248C54.1614 37.6898 56 34.1303 56 30.375C56 25.569 52.9713 21.0938 47.6887 18.0922C47.2734 17.8582 46.7507 17.892 46.375 18.1778C45.997 18.4658 45.8407 18.9473 45.983 19.3906C46.4357 20.8193 46.6667 22.2436 46.6667 23.625C46.6667 33.516 36.1248 41.4158 22.6685 41.607C22.1248 41.6137 21.6558 41.985 21.5462 42.5002C21.4342 43.0154 21.7072 43.5352 22.2019 43.7535C25.7252 45.3038 29.7456 46.1249 33.8336 46.1249H54.8335C55.3328 46.1249 55.7738 45.8212 55.9372 45.3667C56.098 44.9123 55.944 44.4106 55.552 44.1136Z"
          fill={colors["--primary"]}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="56" height="54" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default logo
