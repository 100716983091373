import React, { FC } from "react"
import styled from "styled-components"

type Props = {}

const Title = styled.h2`
  color: var(--primary);
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
`

const SectionTitle: FC<Props> = ({ children, ...props }) => {
  return <Title {...props}>{children}</Title>
}

export default SectionTitle
