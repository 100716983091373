import React, { FC } from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import { useI18next } from "gatsby-plugin-react-i18next"
import { FaComments, FaFile, FaCheck } from "react-icons/fa"
import Accordion from "../Accordion"

const Container = styled.section`
  min-height: 100vh;
  background: var(--off-white);
  margin-top: var(--s16);
`

const ServiceTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: var(--s8);
  margin-top: var(--s16);
  h3 {
    font-size: 3rem;
    font-weight: bold;
    margin-left: var(--s8);
  }
`

const ServiceTitleDolmetschen = styled(ServiceTitle)`
  color: var(--green);
`
const ServiceTitleTranslate = styled(ServiceTitle)`
  color: var(--red);
`

const ServiceTitleKorrektur = styled(ServiceTitle)`
  color: var(--orange);
`
const ServiceDescription = styled.p`
  color: var(--grey);
  margin: var(--s8);
  padding-left: var(--s2);
  display: block;
  border-radius: 7px;
  &.green {
    border-left: 7px solid var(--green);
  }
  &.red {
    border-left: 7px solid var(--red);
  }
  &.orange {
    border-left: 7px solid var(--orange);
  }
`

const Item = styled.div`
  color: var(--grey);
  strong {
    display: block;
    margin-top: var(--s4);
    color: var(--black);
  }
  p {
    margin-top: var(--s4);
  }
  a,
  a:visited,
  a:link {
    color: var(--primary);
  }
  ul {
    list-style: disc;
    margin-left: var(--s8);
  }
`

const Dienstleistungen: FC = () => {
  const { t } = useI18next()
  return (
    <Container id="dienstleistungen_mobile">
      <SectionTitle>{t("dienstleistungen")}</SectionTitle>
      <ServiceTitleDolmetschen>
        <FaComments size="30" />
        <h3> {t("dolmetschen")}</h3>
      </ServiceTitleDolmetschen>
      <ServiceDescription className="green">{t("dolmetschendescription")}</ServiceDescription>
      <Accordion
        color="#915f6d"
        items={[
          {
            title: t("dolmetschen1title"),
            content: (
              <Item>
                <strong>{t("dolmetschen1geeignet")}</strong>
                <p>{t("dolmetschen1p1")}</p>
                <p>{t("dolmetschen1p2")}</p>
              </Item>
            ),
          },
          {
            title: t("dolmetschen2title"),
            content: (
              <Item>
                <strong>{t("dolmetschen2geeignet")}</strong>
                <p>{t("dolmetschen2p1")}</p>
                <p>{t("dolmetschen2p2")}</p>
                <p>{t("dolmetschen2p3")}</p>
              </Item>
            ),
          },
          {
            title: t("dolmetschen3title"),
            content: (
              <Item>
                <strong>{t("dolmetschen3geeignet")}</strong>
                <p>{t("dolmetschen3p1")}</p>
                <p>{t("dolmetschen3p2")}</p>
                <p>{t("dolmetschen3p3")}</p>
              </Item>
            ),
          },
          {
            title: t("dolmetschen4title"),
            content: (
              <Item>
                <strong>{t("dolmetschen4geeignet")}</strong>
                <p>{t("dolmetschen4p1")}</p>
                <p>{t("dolmetschen4p2")}</p>
                <p>{t("dolmetschen4p3")}</p>
              </Item>
            ),
          },
        ]}
      />
      <ServiceTitleTranslate>
        <FaFile size="30" />
        <h3> {t("übersetzen")}</h3>
      </ServiceTitleTranslate>
      <ServiceDescription className="red">{t("übersetzendescription")}</ServiceDescription>
      <Accordion
        color="#5a4262"
        items={[
          {
            title: t("übersetzen1title"),
            content: (
              <Item>
                <p>{t("übersetzen1p1")}</p>
                <ul>
                  <li>{t("übersetzen1li1")}</li>
                  <li>{t("übersetzen1li2")}</li>
                  <li>{t("übersetzen1li3")}</li>
                </ul>
              </Item>
            ),
          },
          {
            title: t("übersetzen2title"),
            content: (
              <Item>
                <p>{t("übersetzen2p1")}</p>
                <ul>
                  <li>{t("übersetzen2li1")}</li>
                  <li>{t("übersetzen2li2")}</li>
                  <li>{t("übersetzen2li3")}</li>
                </ul>
                <p>{t("übersetzen2p2")}</p>
                <p>
                  {t("übersetzen2p3")}
                  <a href="https://www.sprachspagat.ch">{t("übersetzen2a1")}</a>
                  {t("übersetzen2p4")}
                </p>
              </Item>
            ),
          },
        ]}
      />

      <ServiceTitleKorrektur>
        <FaCheck size="30" />
        <h3> {t("korrekturlesen")}</h3>
      </ServiceTitleKorrektur>
      <ServiceDescription className="orange">{t("korrekturdescription1")}</ServiceDescription>
      <Accordion
        color="#49796b"
        items={[
          {
            title: t("korrektur1title"),
            content: (
              <Item>
                <p>{t("korrektur1p1")}</p>
                <p>{t("korrektur1p2")}</p>
              </Item>
            ),
          },
        ]}
      />
    </Container>
  )
}

export default Dienstleistungen
