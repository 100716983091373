import React, { FC } from "react"
import styled from "styled-components"

type Props = {
  variant: "primary" | "outlined"
  onClick?: () => void
  type?: any
}

const MainButton = styled.button`
  border: none;
  padding: var(--s4) var(--s8);
  border-radius: var(--border-radius);
  font-weight: bold;
  font-size: 2.5rem;
  cursor: pointer;
`

const PrimaryButton = styled(MainButton)`
  background: var(--primary);
  color: var(--white);
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--white);
    color: var(--primary-dark);
    border: 1px solid var(--primary);
  }
`

const OutlinedButton = styled(MainButton)`
  background: var(--white);
  border: 1px solid var(--primary);
  color: var(--primary);
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--primary-dark);
    color: var(--white);
  }
`

const Button: FC<Props> = ({ variant, children, ...props }) => {
  if (variant === "primary") {
    return <PrimaryButton {...props}>{children}</PrimaryButton>
  }
  if (variant === "outlined") {
    return <OutlinedButton {...props}>{children}</OutlinedButton>
  }
  return <MainButton>{children}</MainButton>
}

export default Button
