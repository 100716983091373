import React, { FC } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import SectionTitle from "../SectionTitle"
import styled from "styled-components"
import useImages from "../../hooks/useImage"
import Image from "gatsby-background-image"

type Props = {}

const Container = styled.section`
  margin-top: var(--s24);
`

const Content = styled.div`
  background: var(--white);
  padding: var(--s16) var(--s8);
  opacity: 0.92;
  h3 {
    font-weight: bold;
    color: var(--black)
  }
  h3:not(:first-of-type) {
    margin-top: var(--s6);
  }
  ul {
    list-style: disc;
    margin-left: var(--s8);
  }
  li {
    color: var(--grey);
  }
`

const Img = styled(Image)`
  background-repeat: "no-repeat";
  background-size: "cover";
`

const About: FC<Props> = () => {
  const image = useImages(["dolmetschen.jpg"])
  const { t } = useI18next()

  return (
    <Container id="about_mobile">
      <SectionTitle>{t("über mich")}</SectionTitle>
      <Img fluid={image[0].fluid} alt="">
        <Content>
          <h3>{t("ausbildung")}</h3>
          <ul>
            <li>{t("ausbildungul1")}</li>
            <li>{t("ausbildungul2")}</li>
          </ul>

          <h3>{t("weiterbildung")}</h3>
          <ul>
            <li>{t("weiterbildungul1")}</li>
          </ul>

          <h3>{t("persönliche interessen")}</h3>
          <ul>
            <li>{t("interessenul1")}</li>
            <li>{t("interessenul2")}</li>
            <li>{t("interessenul3")}</li>
          </ul>
        </Content>
      </Img>
    </Container>
  )
}

export default About
