import React, { FC, useState, useRef, useEffect } from "react"
import styled from "styled-components"
import Title from "./SectionTitle"
import { useI18next } from "gatsby-plugin-react-i18next"
import { FaMapPin, FaPhone, FaEnvelope } from "react-icons/fa"
import Button from "./Button"

type Props = {}

const Container = styled.section`
  background: var(--primary);
  color: white;
  padding: var(--s12) 0;
  display: grid;
  place-items: center;
`

const SectionTitle = styled(Title)`
  color: white;
`

const ContactContainer = styled.article`
  margin-top: var(--s8);
  justify-self: start;
  > div {
    display: flex;
    align-items: center;
    padding: 0 var(--s8);
    p {
      margin-left: var(--s4);
    }
    &:not(:first-of-type) {
      margin-top: var(--s4);
    }
  }
  @media screen and (min-width: 750px) {
    display: flex;
    align-items: center;
    justify-self: center;
    > div {
      &:not(:last-of-type) {
        border-right: 1px solid var(--off-white);
      }
    }
  }
`

const Offerte = styled.p`
  text-align: center;
  font-size: 2rem;
`
const Form = styled.form`
  width: 100%;
  max-width: 750px;
  padding: var(--s8);
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: white;
  &:not(:first-of-type) {
    margin-top: var(--s6);
  }
`

const Input = styled.input`
  padding: var(--s4) var(--s4);
  margin-top: var(--s2);
  font-size: 1.7rem;
  border: none;
`

const TextArea = styled.textarea`
  padding: var(--s4) var(--s4);
  margin-top: var(--s2);
  font-size: 1.7rem;
  border: none;
  overflow: hidden;
`

const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: var(--s8);
  margin-bottom: var(--s16);
  border-radius: 0;
`

const Contact: FC<Props> = () => {
  const { t } = useI18next()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [betreff, setBetreff] = useState("")
  const [nachricht, setNachricht] = useState("")
  const areaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (areaRef && areaRef.current) {
      areaRef.current.style.height = `${areaRef.current.scrollHeight}px`
    }
  }, [nachricht, areaRef])

  return (
    <Container id="contact">
      <SectionTitle>{t("kontakt")}</SectionTitle>
      <Offerte>{t("offerte")}</Offerte>
      <ContactContainer>
        <div>
          <FaMapPin size="20" />
          <p>Sandra Cox, Buchenweg 28, 5303 Würenlingen</p>
        </div>
        <div>
          <FaPhone size="20" />
          <p>+4176 523 38 16</p>
        </div>
        <div>
          <FaEnvelope size="20" />
          <p>info@sandracox.ch</p>
        </div>
      </ContactContainer>
      <Form action="https://formspree.io/xrgyoevk" method="POST" name="contact">
        <Label>
          {t("ihr nahme")}
          <Input name="name" required value={name} onChange={e => setName(e.target.value)} />
        </Label>
        <Label>
          {t("ihre email")}
          <Input name="email" type="email" required value={email} onChange={e => setEmail(e.target.value)} />
        </Label>
        <Label>
          {t("betreff")}
          <Input name={t("betreff")} required value={betreff} onChange={e => setBetreff(e.target.value)} />
        </Label>
        <Label>
          {t("ihre nachricht")}
          <TextArea
            ref={areaRef}
            name="message"
            required
            value={nachricht}
            onChange={e => setNachricht(e.target.value)}
          />
        </Label>
        <input type="text" name="_gotcha" style={{ display: "none" }} />

        <SubmitButton variant="outlined" type="submit">
          {t("senden")}
        </SubmitButton>
      </Form>
    </Container>
  )
}

export default Contact
