import React, { FC } from "react"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { FaComments, FaAddressCard, FaUser } from "react-icons/fa"
import { navigate } from "@reach/router"

const Container = styled.nav`
  background: var(--primary-dark);
  color: var(--white);
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: var(--s24);
  @media screen and (min-width: 900px) {
    display: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 var(--s8);
  }
  a,
  a:visited,
  a:link {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
  }
`

const MobileFooter: FC = () => {
  const { t } = useI18next()
  return (
    <Container>
      <nav>
        <a
          onClick={e => {
            e.preventDefault()
            navigate("#dienstleistungen_mobile")
          }}
          href="#"
        >
          <FaComments size="22" />
          {t("dienstleistungen")}
        </a>
        <a
          onClick={e => {
            e.preventDefault()
            navigate("#about_mobile")
          }}
          href="#"
          style={{ marginLeft: "-1.5rem" }}
        >
          <FaUser size="22" />
          {t("über mich")}
        </a>
        <a
          onClick={e => {
            e.preventDefault()
            navigate("#contact")
          }}
          href="#"
        >
          <FaAddressCard size="22" />
          {t("kontakt")}
        </a>
      </nav>
    </Container>
  )
}

export default MobileFooter
