import React, { FC, useState, useEffect } from "react"
import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import { useI18next } from "gatsby-plugin-react-i18next"
import { FaComments, FaFile, FaCheck } from "react-icons/fa"
import { AnimateSharedLayout, motion, Variants, AnimatePresence } from "framer-motion"
import { useCssProperties } from "../../hooks/useCssProperties"

const Container = styled.section`
  background: var(--off-white);
  padding: var(--s16);
  min-height: 80vh;
`

const TabBar = styled.div`
  background: var(--white);
  box-shadow: var(--shadow-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  padding: var(--s8);
  margin-top: var(--s16);
  .underline {
    height: var(--s1);
    width: 100%;
    grid-row-start: 2;
    grid-column: 1 / 3;
    &.green {
      background: var(--green);
    }
    &.red {
      background: var(--red);
    }
    &.orange {
      background: var(--orange);
    }
  }
  @media screen and (min-width: 1250px) {
    justify-content: flex-start;
  }
`

const ServiceTitle = styled.button`
  display: grid;
  background: none;
  border: none;
  grid-template-rows: auto 1fr;
  grid-template-rows: 1fr auto;
  align-items: center;
  margin-left: var(--s8);
  cursor: pointer;
  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-left: var(--s8);
    grid-row-start: 1;
  }
  svg {
    grid-row-start: 1;
  }
  @media screen and (min-width: 1250px) {
    &:not(:first-of-type) {
      margin-left: var(--s48);
    }
  }
`

const ServiceTitleDolm = styled(ServiceTitle)`
  color: var(--green);
`
const ServiceTitleTranslate = styled(ServiceTitle)`
  color: var(--red);
`

const ServiceTitleKorrektur = styled(ServiceTitle)`
  color: var(--orange);
`

const CardContainer = styled(motion.div)`
  display: grid;
  margin-top: var(--s16);
  grid-gap: var(--s12);
  grid-template-columns: repeat(auto-fill, minmax(50ch, 1fr));
`

const Card = styled(motion.article)<{ color: string }>`
  background: var(--white);
  box-shadow: var(--shadow-md);
  border-radius: var(--border-radius);
  border-bottom: 6px solid ${props => props.color};
  padding: var(--s8);
  text-align: center;
  color: var(--grey);

  h4 {
    color: ${props => props.color};
    font-size: 2.5rem;
    font-weight: bold;
  }
  strong {
    display: block;
    margin-top: var(--s4);
    color: var(--black);
  }
  p {
    margin-top: var(--s4);
  }
  a,
  a:visited,
  a:link {
    color: var(--primary);
  }
  ul {
    color: var(--black);
    font-weight: bold;
    margin-top: var(--s4);
  }
  li {
    border-bottom: 1px solid var(--grey);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: var(--s2);
  }
`

const ServiceDescription = styled(motion.p)<{ color: string }>`
  background: var(--white);
  z-index: 1;
  color: var(--grey);
  display: inline-block;
  width: 80ch;
  padding: var(--s8);
  box-shadow: var(--shadow-md);
  border-radius: var(--border-radius);
  margin-top: var(--s8);
  border-left: 10px solid ${props => props.color};
`

const DesktopDienstleistungen: FC = () => {
  const { t } = useI18next()
  const [activeCategory, setActiveCategory] = useState(0)
  const colors = useCssProperties(["--green", "--red", "--orange"])

  return (
    <Container id="dienstleistungen">
      <SectionTitle>{t("dienstleistungen")}</SectionTitle>
      <AnimateSharedLayout>
        <TabBar>
          <ServiceTitleDolm onClick={() => setActiveCategory(0)}>
            <FaComments size="30" />
            <h3 style={{ color: "" }}> {t("dolmetschen")}</h3>
            {activeCategory === 0 && <motion.div layoutId="underline" className="underline green" />}
          </ServiceTitleDolm>
          <ServiceTitleTranslate onClick={() => setActiveCategory(1)}>
            <FaFile size="30" />
            <h3> {t("übersetzen")}</h3>
            {activeCategory === 1 && <motion.div layoutId="underline" className="underline red" />}
          </ServiceTitleTranslate>
          <ServiceTitleKorrektur onClick={() => setActiveCategory(2)}>
            <FaCheck size="30" />
            <h3> {t("korrekturlesen")}</h3>
            {activeCategory === 2 && <motion.div layoutId="underline" className="underline orange" />}
          </ServiceTitleKorrektur>
        </TabBar>
      </AnimateSharedLayout>
      <AnimateSharedLayout>
        <AnimatePresence key="ap1">
          {activeCategory === 0 && (
            <>
              <ServiceDescription color={colors["--green"]} animate layoutId="desc">
                {t("dolmetschendescription")}
              </ServiceDescription>
              <CardContainer>
                <Card animate key="card_1" layoutId="card1" color={colors["--green"]}>
                  <FaComments color={colors["--green"]} size="25" />
                  <motion.h4>{t("dolmetschen1title")}</motion.h4>
                  <motion.strong>{t("dolmetschen1geeignet")}</motion.strong>
                  <motion.p>{t("dolmetschen1p1")}</motion.p>
                  <motion.p>{t("dolmetschen1p2")}</motion.p>
                </Card>
                <Card animate key="card_2" layoutId="card2" color={colors["--green"]}>
                  <FaComments color={colors["--green"]} size="25" />
                  <motion.h4>{t("dolmetschen2title")}</motion.h4>
                  <motion.strong>{t("dolmetschen2geeignet")}</motion.strong>
                  <motion.p>{t("dolmetschen2p1")}</motion.p>
                  <motion.p>{t("dolmetschen2p2")}</motion.p>
                  <motion.p>{t("dolmetschen2p3")}</motion.p>
                </Card>
                <Card animate key="card_3" layoutId="card3" color={colors["--green"]}>
                  <FaComments color={colors["--green"]} size="25" />
                  <motion.h4>{t("dolmetschen3title")}</motion.h4>
                  <motion.strong>{t("dolmetschen3geeignet")}</motion.strong>
                  <motion.p>{t("dolmetschen3p1")}</motion.p>
                  <motion.p>{t("dolmetschen3p2")}</motion.p>
                  <motion.p>{t("dolmetschen3p3")}</motion.p>
                </Card>
                <Card animate key="card_4" layoutId="card4" color={colors["--green"]}>
                  <FaComments color={colors["--green"]} size="25" />
                  <motion.h4>{t("dolmetschen1title")}</motion.h4>
                  <motion.strong>{t("dolmetschen4geeignet")}</motion.strong>
                  <motion.p>{t("dolmetschen4p1")}</motion.p>
                  <motion.p>{t("dolmetschen4p2")}</motion.p>
                  <motion.p>{t("dolmetschen4p3")}</motion.p>
                </Card>
              </CardContainer>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence key="ap2">
          {activeCategory === 1 && (
            <>
              <ServiceDescription color={colors["--red"]} animate layoutId="desc">
                {t("übersetzendescription")}
              </ServiceDescription>
              <CardContainer>
                <Card animate key="card_5" layoutId="card1" color={colors["--red"]}>
                  <FaFile color={colors["--red"]} size="25" />
                  <motion.h4>{t("übersetzen1title")}</motion.h4>
                  <motion.p>{t("übersetzen1p1")}</motion.p>
                  <motion.ul>
                    <motion.li>{t("übersetzen1li1")}</motion.li>
                    <motion.li>{t("übersetzen1li2")}</motion.li>
                    <motion.li>{t("übersetzen1li3")}</motion.li>
                  </motion.ul>
                </Card>
                <Card animate key="card_6" layoutId="card2" color={colors["--red"]}>
                  <FaFile color={colors["--red"]} size="25" />
                  <motion.h4>{t("übersetzen2title")}</motion.h4>
                  <motion.p>{t("übersetzen2p1")}</motion.p>
                  <motion.ul>
                    <motion.li>{t("übersetzen2li1")}</motion.li>
                    <motion.li>{t("übersetzen2li2")}</motion.li>
                    <motion.li>{t("übersetzen2li3")}</motion.li>
                  </motion.ul>
                  <motion.p>{t("übersetzen2p2")}</motion.p>
                  <motion.p>
                    {t("übersetzen2p3")}
                    <motion.a href="https://www.sprachspagat.ch">{t("übersetzen2a1")}</motion.a>
                    {t("übersetzen2p4")}
                  </motion.p>
                </Card>
              </CardContainer>
            </>
          )}
        </AnimatePresence>
        <AnimatePresence key="ap3">
          {activeCategory === 2 && (
            <>
              <ServiceDescription color={colors["--orange"]} animate layoutId="desc">
                {t("korrekturdescription1")}
              </ServiceDescription>
              <CardContainer>
                <Card animate key="card_7" layoutId="card1" color={colors["--orange"]}>
                  <FaCheck color={colors["--orange"]} size="25" />
                  <motion.h4>{t("korrektur1title")}</motion.h4>
                  <motion.p>{t("korrektur1p1")}</motion.p>
                  <motion.p>{t("korrektur1p2")}</motion.p>
                </Card>
              </CardContainer>
            </>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Container>
  )
}

export default DesktopDienstleistungen
