import React, { FC } from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import useImages from "../../hooks/useImage"
import styled from "styled-components"
import Image from "gatsby-image"
import Button from "../Button"
import { navigate } from "@reach/router"
import { motion, Variants } from "framer-motion"

const Container = styled(motion.header)`
  height: 100vh;
  background: var(--white);
  display: grid;
  grid-template-columns: minmax(35rem, 4fr) 5fr;
  div {
    display: grid;
    grid-template-rows: 10fr 20fr 15fr 15fr;
    align-items: center;
    justify-items: center;
    justify-content: center;
    padding: var(--s16);
  }
  h1 {
    grid-row-start: 2;
    color: var(--primary);
    font-weight: bold;
    font-size: 10rem;
    line-height: 0.8;
    margin-left: var(--s8);
    letter-spacing: 0.05em;
    > span {
      font-weight: normal;
      font-size: 5rem;
      display: inline-block;
    }
  }
  @media screen and (max-width: 1406px) {
    h1 {
      font-size: 8rem;
      > span {
        font-size: 4.5rem;
      }
    }
  }
  @media screen and (max-width: 1270px) {
    h1 {
      font-size: 6rem;
      > span {
        font-size: 4rem;
      }
    }
  }
  @media screen and (max-width: 1159px) {
    h1 {
      font-size: 6rem;
      > span {
        font-size: 3rem;
      }
    }
  }
  @media screen and (max-width: 963px) {
    h1 {
      font-size: 6rem;
      > span {
        font-size: 2.6rem;
      }
    }
  }
`

const Quote = styled.p`
  grid-row-start: 3;
  font-style: italic;
  border-left: 5px solid var(--primary);
  font-size: 2rem;
  color: var(--gray);
  padding-left: var(--s4);
  margin-left: var(--s8);
  display: block;
`

const CTA = styled(Button)`
  width: 90%;
  margin: var(--s16) auto;
  text-align: center;
  display: block;
  grid-row-start: 4;
`

const Img = styled(Image)``

const containerVariants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  loaded: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.04,
      damping: 300,

    },
  },
}
const textVariants: Variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  loaded: {
    opacity: 1,
    y: 0,
  },
}
const DesktopHeader: FC = () => {
  const { t, language } = useI18next()
  const headerImage = useImages(["desktopheader.jpg"])
  return (
    <Container variants={containerVariants} initial="initial" animate="loaded">
      <Img fluid={headerImage[0].fluid} alt="Sandra Cox" />
      <div>
        <h1>
          Sandra Cox <br />
          {[...t("konferenzdolmetscherin")].map((c, i) => (
            <motion.span variants={textVariants} key={i}>
              {c}
            </motion.span>
          ))}
        </h1>
        <Quote>{t("quote")}</Quote>
        <CTA onClick={() => navigate("#dienstleistungen")} variant="primary">
          {t("meine dienstleistungen")}
        </CTA>
      </div>
    </Container>
  )
}

export default DesktopHeader
