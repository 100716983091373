import React, { FC } from "react"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import useImages from "../../hooks/useImage"
import UnstyledLogo from "../Logo"
import Image from "gatsby-image"
import Button from "../Button"
import { navigate } from "@reach/router"

const Container = styled.header`
  min-height: 100vh;
  background: var(--white);
  > h1 {
    color: var(--primary);
    font-weight: bold;
    font-size: 6rem;
    line-height: 0.8;
    margin-left: var(--s8);
    > span {
      font-weight: normal;
      font-size: 3rem;
    }
  }
  blockquote {
    font-style: italic;
    background: var(--primary);
    color: var(--white);
    padding: var(--s4);
    display: block;
    text-align: center;
  }
`

const Logo = styled(UnstyledLogo)`
  margin-left: var(--s8);
  margin-top: var(--s2);
`

const Img = styled(Image)`
  margin-top: var(--s16);
`

const CTA = styled(Button)`
  width: 90%;
  margin: var(--s16) auto;
  text-align: center;
  display: block;
`

const MobileHeader: FC = () => {
  const { t } = useI18next()
  const headerImage = useImages(["mobile_img.jpg"])
  return (
    <Container>
      <Logo />
      <h1>
        Sandra Cox <br />
        <span>{t("konferenzdolmetscherin")}</span>
      </h1>
      <Img fluid={headerImage[0].fluid} alt="Sandra Cox" />
      <CTA onClick={() => navigate("#dienstleistungen_mobile")} variant="outlined">
        {t("meine dienstleistungen")}
      </CTA>
      <blockquote>{t("quote")}</blockquote>
    </Container>
  )
}

export default MobileHeader
