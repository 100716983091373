import React, { FC, useState } from "react"
import styled from "styled-components"
import Logo from "../Logo"
import { FaComment, FaAddressCard, FaUser } from "react-icons/fa"
import { navigate } from "@reach/router"
import { useI18next, Link } from "gatsby-plugin-react-i18next"
import { motion, Variants, AnimatePresence, useViewportScroll } from "framer-motion"
import { useCssProperties } from "../../hooks/useCssProperties"

const Container = styled(motion.div)`
  width: var(--sidebar-width);
  background: var(--white);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: var(--s8) 0;
  transition: all 0.3s ease-out;
  @media screen and (max-width: 900px) {
    display: none;
  }

  .link,
  .link:visited,
  .link:active {
    text-decoration: none;
    color: var(--primary);
    font-size: 2rem;
    position: relative;
  }
`

const NavIcons = styled.nav`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  grid-gap: var(--s16);
  margin-top: -1rem;
  > svg {
    cursor: pointer;
  }
`

const ToolTip = styled(motion.div)`
  position: absolute;
  background: var(--white);
  color: var(--primary);
  padding: var(--s4) var(--s12);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  border: 1px solid var(--primary);
  z-index: 999;
  top: -5px;
`

const variants: Variants = {
  hidden: {
    opacity: 0,
    x: -200,
  },
  visible: {
    opacity: 1,
    x: -10,
    transition: {
      damping: 300,
    },
  },
}

const Sidebar: FC = () => {
  const { t } = useI18next()
  const [activeTooltip, setActiveTooltip] = useState(-1)
  const colors = useCssProperties(["--primary"])
  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ damping: 300 }}>
      <Link to="/">
        <Logo />
      </Link>
      <NavIcons>
        <a
          onMouseEnter={() => setActiveTooltip(0)}
          onMouseLeave={() => setActiveTooltip(-1)}
          className="link"
          onClick={e => {
            e.preventDefault()
            navigate("#dienstleistungen")
          }}
          href="#"
        >
          <FaComment size="35" color={colors["--primary"]} />
          <AnimatePresence>
            {activeTooltip === 0 && (
              <ToolTip variants={variants} initial="hidden" animate="visible" exit="hidden">
                {t("dienstleistungen")}
              </ToolTip>
            )}
          </AnimatePresence>
        </a>
        <a
          onMouseEnter={() => setActiveTooltip(1)}
          onMouseLeave={() => setActiveTooltip(-1)}
          className="link"
          onClick={e => {
            e.preventDefault()
            navigate("#about")
          }}
          href="#"
        >
          <FaUser size="35" color={colors["--primary"]} />
          <AnimatePresence>
            {activeTooltip === 1 && (
              <ToolTip variants={variants} initial="hidden" animate="visible" exit="hidden">
                {" "}
                {t("über mich")}
              </ToolTip>
            )}
          </AnimatePresence>
        </a>
        <a
          className="link"
          onMouseEnter={() => setActiveTooltip(2)}
          onMouseLeave={() => setActiveTooltip(-1)}
          onClick={e => {
            e.preventDefault()
            navigate("#contact")
          }}
          href="#"
        >
          <FaAddressCard size="35" color={colors["--primary"]} />
          <AnimatePresence>
            {activeTooltip === 2 && (
              <ToolTip variants={variants} initial="hidden" animate="visible" exit="hidden">
                {" "}
                {t("kontakt")}
              </ToolTip>
            )}
          </AnimatePresence>
        </a>
      </NavIcons>
    </Container>
  )
}

export default Sidebar
