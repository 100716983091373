import React, { FC } from "react"
import styled from "styled-components"
import useImages from "../../hooks/useImage"
import { useI18next } from "gatsby-plugin-react-i18next"
import SectionTitle from "../SectionTitle"
import Image from "gatsby-image"

const Container = styled.section`
  background: var(--off-white);
  min-height: 70vh;
  padding: var(--s24);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--s24);
`

const ContentContainer = styled.div`
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-md);
  display: grid;
  grid-template-columns: 6fr 5fr;
  height: 100%;
  margin-top: var(--s16);
  max-width: 1500px;
  width: 100%;
`

const Content = styled.div`
  padding: var(--s24);
  color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    color: var(--primary);
    font-size: 2.25rem;
    font-weight: bold;
    &:not(:first-of-type) {
      margin-top: var(--s16);
    }
  }
  ul {
    list-style: disc;
    margin-left: var(--s8);
  }
  li {
    font-size: 1.8rem;
  }
`

const Img = styled(Image)`
  display: block;
  object-position: right right;
  object-fit: cover;
`

const DesktopAbout: FC = () => {
  const image = useImages(["dolmetschen.jpg"])
  const { t } = useI18next()
  return (
    <Container id="about">
      <SectionTitle>{t("über mich")}</SectionTitle>
      <ContentContainer>
        <Content>
          <div>
            <h3>{t("ausbildung")}</h3>
            <ul>
              <li>{t("ausbildungul1")}</li>
              <li>{t("ausbildungul2")}</li>
            </ul>
            <h3>{t("weiterbildung")}</h3>
            <ul>
              <li>{t("weiterbildungul1")}</li>
            </ul>
            <h3>{t("persönliche interessen")}</h3>
            <ul>
              <li>{t("interessenul1")}</li>
              <li>{t("interessenul2")}</li>
              <li>{t("interessenul3")}</li>
            </ul>
          </div>
        </Content>
        <Img fluid={image[0].fluid} alt="Sandra Cox" />
      </ContentContainer>
    </Container>
  )
}

export default DesktopAbout
